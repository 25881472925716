<template>
    <div>
        <Upload
            style="width: 250px"
            multiple
            type="drag"
            :before-upload="beforeUpload"
            v-if="(multiple && fileLength != uploadArr.length) || (!multiple && uploadArr.length == 0)"
            :disabled="disabled"
        >
            <div style="padding: 20px 0">
                <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
                <p>点击或将文件拖拽到这里上传</p>
            </div>
        </Upload>
        <div v-if="uploadArr.length != 0">
            <div class="upload_list" v-for="(item, index) in uploadArr" :key="item.name">
                <div class="upload_list_name">{{ item.name }}</div>
                <div class="upload_list_delete submit" v-if="showDownBtn" @click="add_download(index, item.name)">下载</div>
                <div class="upload_list_delete" @click="add_delete(index, item.name)">删除</div>
            </div>
        </div>
    </div>
</template>

<script>
import proxy from "@/api/proxy"
export default {
    props: {
        accept: String,
        multiple: {
            type: Boolean,
            default: false,
        }, //是否允许多选，默认false
        maxSize: {
            type: Number,
            default: 10,
        }, //文件限制大小
        fileLength: {
            // 最多上个文件个数
            type: [String, Number],
            default: 1,
        },
        target: {
            type: [String, Number],
            default: null,
        },
        defaultFileList: {
            type: Array,
            default: [],
        },
        showDownBtn: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            uploadArr: [], //存储已上传的数组

            loadFile: null,
            disabled: false,
        }
    },
    methods: {
        // 删除某一项
        add_delete(index) {
            this.uploadArr.splice(index, 1)
            this.$emit("success", this.uploadArr)
        },
        add_download(index, name) {
            let audioUrl = this.uploadArr[index].url
            this.axios({
                method: "get",
                url: `${audioUrl}`, // 必须显式指明响应类型是一个Blob对象，这样生成二进制的数据，才能通过window.URL.createObjectURL进行创建成功
                responseType: "blob",
            }).then(res => {
                if (!res) {
                    return
                } // 将lob对象转换为域名结合式的url
                let blobUrl = window.URL.createObjectURL(res.data)
                let link = document.createElement("a")
                document.body.appendChild(link)
                link.style.display = "none"
                link.href = blobUrl // 设置a标签的下载属性，设置文件名及格式，后缀名最好让后端在数据格式中返回
                link.download = name // 自触发click事件
                link.click()
                document.body.removeChild(link)
                window.URL.revokeObjectURL(blobUrl)
            })
        },
        // 上传
        beforeUpload(vla) {
            let type_index = vla.type.lastIndexOf("/")
            let type = vla.type.substring(type_index + 1, vla.length)
            let number = this.conversion(vla.size)
            console.log("type", type)
            if (
                type == "x-m4a" ||
                type === "cda" ||
                type === "wav" ||
                type === "mp3" ||
                type === "aif" ||
                type === "aiff" ||
                type === "mid" ||
                type === "wma" ||
                type === "ra" ||
                type === "vqf" ||
                type === "ape" ||
                type === "mpeg"
            ) {
                if (number > this.maxSize * 1024) {
                    this.prompt("warning", { title: "音频上传", desc: "音频文件大于100M", top: 50, duration: 2 })
                    return false
                } else {
                    for (let i = 0; i < this.uploadArr.length; i++) {
                        if (vla.name === this.uploadArr[i].name) {
                            this.prompt("warning", { title: "上传重复", desc: "请把相同的文件删除后在进行上传", top: 50, duration: 2 })
                            return false
                        }
                    }
                }
                this.loadFile = vla
                this.signature(type, vla.name)
                return false
            }

            this.prompt("error", { title: "文件上传失败", desc: "暂不支持该文件类型上传", top: 50, duration: 2 })
            return false
        },

        // 根据上传的每一条保存数据
        signature(name) {
            try {
                this.disabled = true
                this.$Message.loading({
                    content: "正在上传，请稍等",
                })
                this.$get("/datamsg/api/common/file/getWebPolicy?bucketName=liefeng").then(async res => {
                    if (res.code == 200) {
                        let now = Date.parse(new Date()) / 1000
                        this.uploadData = {
                            name: now + name,
                            host: res.data.host,
                            key: res.data.key + now + name,
                            policy: res.data.encodedPolicy,
                            OSSAccessKeyId: res.data.accessId,
                            success_action_status: "200",
                            signature: res.data.postSignature,
                        }
                        let fromdata = new FormData()
                        fromdata.append("name", this.uploadData.name)
                        fromdata.append("host", this.uploadData.host)
                        fromdata.append("key", this.uploadData.key)
                        fromdata.append("policy", this.uploadData.policy)
                        fromdata.append("OSSAccessKeyId", this.uploadData.OSSAccessKeyId)
                        fromdata.append("success_action_status", this.uploadData.success_action_status)
                        fromdata.append("signature", this.uploadData.signature)
                        fromdata.append("file", this.loadFile)
                        await this.$post(proxy["/oss"].target, fromdata, { "Content-Type": "multipart/form-data" })
                            .then(res => {
                                this.uploadArr.push({
                                    name: this.uploadData.name,
                                    url: this.uploadData.host + this.uploadData.key,
                                })
                                this.disabled = false
                                this.$Message.destroy()
                                this.$emit("success", this.uploadArr)
                            })
                            .catch(err => {
                                this.disabled = false
                                this.$Message.destroy()
                                this.$Message.error({
                                    content: `文件上传失败`,
                                    background: true,
                                    duration: 2,
                                })
                            })
                    }
                })
            } catch (err) {
                this.disabled = false
            }
        },
        // 字节换算
        conversion(vla) {
            let number = 0
            if (vla < 0.1 * 1024) {
                //小于0.1KB，则转化成B
                number = vla.toFixed(2) //"B"
            } else if (vla < 0.1 * 1024 * 1024) {
                //小于0.1MB，则转化成KB
                number = (vla / 1024).toFixed(2) //"KB"
            } else if (vla < 0.1 * 1024 * 1024 * 1024) {
                //小于0.1GB，则转化成MB
                number = (vla / (1024 * 1024)).toFixed(2) //"MB"
            } else {
                //其他转化成GB
                number = (vla / (1024 * 1024 * 1024)).toFixed(2) //"GB"
            }
            return number
        },
        // 提示
        prompt(type, { title, desc, top, duration }) {
            if (type === "warning") {
                this.$Notice.warning({
                    title: title,
                    desc: desc,
                })
            } else if (type === "error") {
                this.$Notice.error({
                    title: title,
                    desc: desc,
                })
            }
            this.$Notice.config({
                top: top || 50,
                duration: duration || 2,
            })
        },
    },
    watch: {
        defaultFileList: {
            handler(val) {
                if (val) {
                    this.uploadArr = val
                }
            },
            deep: true,
            immediate: true,
        },
    },
}
</script>

<style lang="less" scoped>
.upload_list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5px;

    .upload_list_name {
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        margin-right: 5px;
    }
    .upload_list_delete {
        height: 30px;
        width: 45px;
        font-size: 14px;
        color: #fff;
        background: red;
        line-height: 30px;
        text-align: center;
    }
    .submit {
        background: #29be6c;
    }
}
</style>